<template>
    <Terminal />
</template>

<script>

import Terminal from '@/view/Terminal';

export default {
  
    name: 'App',

    components: {
        Terminal
    }

}
</script>

<style lang="scss">

@import '@/style/main.scss';

</style>