<template>
    <main>

        <slot />

    </main>
</template>

<script>

export default {

    name: 'AppMain'

}

</script>

<style lang="scss" module>

</style>